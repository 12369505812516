<template>
  <div class="spareParts-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" :content="content" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <!-- 表单 -->
        <ml-form
          ref="sparePartsFormRef"
          labelWidth="200px"
          style="width: 1200px; margin: 20px auto 40px"
          :model="sparePartsForm"
          :rules="sparePartsFormRules"
        >
          <!-- 提示标题 -->
          <el-divider content-position="left" style="font-weight: bold">备品备件信息</el-divider>
          <el-row>
            <el-col :span="12">
              <ml-select
                prop="companyId"
                placeholder="请选择阿米巴"
                :options="unitOptions"
                label="阿米巴:"
                keyName="companyId"
                labelName="companyName"
                valueName="companyId"
                v-model="sparePartsForm.companyId"
                @change="changeCompany"
              />
            </el-col>
            <el-col :span="12">
              <ml-input
                prop="partName"
                label="备件名称:"
                placeholder="请输入备件名称"
                v-model.trim="sparePartsForm.partName"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-input
                prop="partCode"
                label="备件编号:"
                placeholder="请输入备件编号"
                v-model.trim="sparePartsForm.partCode"
              />
            </el-col>
            <el-col :span="12">
              <!-- 所属项目 -->
              <ml-select
                prop="projectId"
                placeholder="请选择所属项目"
                :options="projectData"
                label="所属项目:"
                keyName="projectId"
                labelName="projectName"
                valueName="projectId"
                v-model="sparePartsForm.projectId"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-date-picker
                prop="productDate"
                placeholder="请选择生产日期"
                label="生产日期:"
                type="date"
                v-model="sparePartsForm.productDate"
              />
            </el-col>
            <el-col :span="12">
              <ml-input
                prop="model"
                label="规格/型号:"
                placeholder="请输入规格/型号"
                v-model.trim="sparePartsForm.model"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-input
                prop="unit"
                label="单位:"
                placeholder="请输入单位"
                v-model.trim="sparePartsForm.unit"
              />
            </el-col>
            <el-col :span="12">
              <ml-input
                prop="deadline"
                label="使用期限(月):"
                placeholder="请输入使用期限"
                v-model.trim="sparePartsForm.deadline"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-input-number
                prop="planStock"
                label="计划库存:"
                :min="0"
                placeholder="请输入计划库存"
                v-model="sparePartsForm.planStock"
              />
            </el-col>
            <el-col :span="12">
              <ml-input-number
                prop="realStock"
                label="实际库存:"
                :min="0"
                placeholder="请输入实际库存"
                :disabled="!!partId"
                v-model="sparePartsForm.realStock"
              />
            </el-col>
          </el-row>

          <ml-input
            prop="storageAddress"
            label="存放地址:"
            placeholder="请输入存放地址"
            v-model.trim="sparePartsForm.storageAddress"
            :maxlength="100"
            showWordLimit
          />
          <el-row v-if="!partId">
            <el-col :span="24">
              <ml-select
                prop="orderProcess.processId"
                placeholder="请选择审批流程"
                :options="processData"
                label="审批流程:"
                keyName="processId"
                labelName="processName"
                valueName="processId"
                v-model="sparePartsForm.orderProcess.processId"
              />
            </el-col>
          </el-row>
          <el-row v-if="!partId">
            <el-col :span="24">
              <ml-input
                prop="orderProcess.content"
                label="入库原因:"
                placeholder="请输入入库原因"
                v-model.trim="sparePartsForm.orderProcess.content"
                type="textarea"
                row="3"
                :maxlength="200"
                showWordLimit
              />
            </el-col>
          </el-row>
          <ml-button
            submitText="提交"
            submitIcon="el-icon-upload2"
            cancelIcon="el-icon-circle-close"
            cancelText="返回上一页"
            native-type="button"
            @click-submit="clickSubmitSparePartsr"
            @click-cancel="clickCancelSparePartsr"
          />
        </ml-form>
      </el-scrollbar>
    </ml-tip>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getTabberData, tabberHeight, searchParams, formetData } from '@/utils'

export default {
  name: 'SparePartsAction',
  setup() {
    const { commit, getters, dispatch } = useStore()
    const route = useRoute()
    const router = useRouter()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    // 获取路径参数
    const { partId } = route.query
    // 设置导航标题文本
    const content = ref(partId ? '修改备品' : '新增备品')
    /* 开始 表单 **********************************/
    // 详情数据
    const sparePartsFormRef = ref()
    // 表单配置
    const sparePartsForm = reactive({
      companyId: '',
      partName: '',
      partCode: '',
      productDate: '',
      model: '',
      unit: '',
      deadline: '',
      projectId: '',
      storageAddress: '',
      planStock: 0,
      realStock: 0,
      orderProcess: {
        processId: '',
        businessType: 'spare_part',
        nodeName: '',
        content: '',
        projectId: ''
      }
    })

    // 单位名下拉列表
    const unitOptions = ref([])
    // 所属项目下拉列表
    const projectData = ref([])
    // 流程列表下拉列表
    const processData = ref([])
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = { parent: 0 }
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    const changeCompany = () => {
      sparePartsForm.projectId = ''
      sparePartsForm.orderProcess.processId = ''
    }
    watch(
      () => sparePartsForm.companyId,
      newvalue => {
        getProjectData(newvalue)
        getProcessList(newvalue)
      }
    )

    const getProcessList = companyId => {
      dispatch('fetchOrderProcesss', { companyId }).then(data => {
        if (data.code === 200) {
          processData.value = data.data || []
        }
      })
    }

    // 表单校验
    const sparePartsFormRules = {
      companyId: [{ required: true, message: '请选择阿米巴 ', trigger: 'blur' }],
      partName: [{ required: true, message: '请输入备品名称', trigger: 'blur' }],
      partCode: [{ required: true, message: '请输入备品编号', trigger: 'blur' }],
      productDate: [{ required: true, message: '请输入生产日期', trigger: 'blur' }],
      model: [{ required: true, message: '请输入规格/型号', trigger: 'blur' }],
      unit: [{ required: true, message: '请输入单位', trigger: 'blur' }],
      deadline: [{ required: true, message: '请输入使用期限', trigger: 'blur' }],
      projectId: [{ required: true, message: '请选择所属项目', trigger: 'blur' }],
      storageAddress: [{ required: true, message: '请输入存放地址', trigger: 'blur' }],
      planStock: [{ required: true, message: '请输入计划年限', trigger: 'blur' }],
      realStock: [{ required: true, message: '请输入实际年限', trigger: 'blur' }],
      'orderProcess.processId': [{ required: true, message: '请选择审批流程', trigger: 'blur' }],
      'orderProcess.content': [{ required: true, message: '请输入入库原因', trigger: 'blur' }]
    }
    // 提交表单
    const clickSubmitSparePartsr = () => {
      sparePartsFormRef.value.CustomFormRef.validate()
        .then(() => {
          const sparePartsParams = searchParams(sparePartsForm, ['productDate'], true)
          if (sparePartsForm.productDate) {
            sparePartsParams.productDate = formetData(sparePartsForm.productDate, 'year')
          }
          sparePartsParams.orderProcess.projectId = sparePartsForm.projectId
          sparePartsParams.orderProcess.nodeName = '【新增备品入库】'
          if (content.value === '新增备品') {
            dispatch('fetchAddSparePart', sparePartsParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/spareParts' })
              }
            })
          } else {
            sparePartsParams.partId = partId
            dispatch('fetchUpdateSparePart', sparePartsParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/spareParts' })
              }
            })
          }
        })
        .catch(() => {})
    }
    // 取消表单
    const clickCancelSparePartsr = () => {
      router.push({ path: '/spareParts' })
    }

    const getsparePartsDetails = () => {
      dispatch('fetchGetsparePart', partId).then(data => {
        if (data && data.code === 200) {
          Object.keys(sparePartsForm).map(item => {
            if (item === 'orderProcess') {
              sparePartsForm.orderProcess = {}
            } else {
              sparePartsForm[item] = data.data[item]
            }
          })
        }
      })
    }

    /* 结束 用户表单 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
      if (data.length > 0) {
        sparePartsForm.companyId = data[0].companyId
      }
      if (content.value === '修改备品') {
        await getsparePartsDetails()
      }
    })
    return {
      content,
      sparePartsForm,
      unitOptions,
      projectData,
      sparePartsFormRules,
      clickSubmitSparePartsr,
      clickCancelSparePartsr,
      tabberHeight,
      sparePartsFormRef,
      changeCompany,
      processData,
      getProcessList,
      partId
    }
  }
}
</script>

<style lang="scss">
.spareParts-action {
  position: relative;
  .ml-tree {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 10px 15px;
    .el-tree {
      > .el-tree-node {
        > .el-tree-node__children {
          > .el-tree-node {
            > .el-tree-node__children {
              white-space: break-spaces;
              > .el-tree-node {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  .picTab {
    .el-tabs__nav-wrap::after {
      background-color: #fff !important;
    }
    .el-tabs__nav-scroll {
      padding: 0 40px;
    }
  }
  .man-class {
    display: inline-block;
    min-width: 100px;
  }
}
.ltitude-and-longitude {
  .el-dialog__body {
    padding: 0 !important;
  }
  .el-dialog__header {
    display: none;
  }
}
</style>
